import SiteHelmet from "../components/siteHelmet";
import * as React from "react";
import ThemeProvider from "../components/themeProvider";
import ReactMarkdown from "react-markdown";
import {VStack} from "@chakra-ui/react";
import FooterText from "../components/footerText";
import {BigHeading} from "../components/text";
import {ReadableWrap} from "../components/wrap";
import Card from "../components/card";

const content = `
**How do I download Run/walk?**

Simply navigate to the App Store on your Apple Watch, and search for "Run/walk". Tap download and you're good to go!

**How do I start a run?**

Firstly, open the Run/walk app. Then, tap the type of run you would like to complete: time or distance. This changes
the way we measure your intervals. Select how long you would like your intervals to be and select continue. Remember
to choose "Indoor run" if you're running on a treadmill.

**Can I use Run/walk on a treadmill?**

Yes! Just toggle the "Indoor run" option before you begin. (It's beneath the warm-up and cool-down options).

**Can I listen to music at the same time?**

Yes! There are two ways to do this:

Firstly, you can connect your bluetooth headphones to your Apple Watch. Start playing music at any time (before or even
during a run) and you will still hear when to start/stop running. Use the Now Playing screen in the app by swiping right
on the run page to control tracks.

Alternatively, you can play music via another device. In this case, you won't hear Run/walk prompt through your headphones,
but they will be played out loud, and you'll feel a tap on your wrist.

**What data does Run/walk collect?**

During your run, Run/walk uses HealthKit to display your distance, pace, heart rate, active kilocalories burnt amongst
other metrics. We do not collect this data from your device, and use it exclusively on device during your run.

If you give Run/walk access, it can use your location to map your run so that you can see where you ran. We do not
send this data off your device, and use it exclusively on device during your run. At the end of your run, your route
will be saved to HealthKit.

For more information, please see our *[privacy policy](https://runwalk.benrobinson.dev)* or send us an email at
*[hello@benrobinson.dev](mailto:hello@benrobinson.dev)*.

**I have a different question.**

Feel free to email us at *[hello@benrobinson.dev](mailto:hello@benrobinson.dev)* with any questions or feedback.
`;

export default function SupportPage() {
    return (
        <>
            <SiteHelmet title="Support | Run/walk" description="An interval trainer for Apple Watch" />
            <ThemeProvider>
                <ReadableWrap>
                    <VStack
                        spacing="40px"
                        marginTop="12vh"
                        marginBottom="40px"
                        align="left"
                        width="100%"
                    >
                        <BigHeading textAlign="left">
                            Support
                        </BigHeading>
                        <Card>
                            <VStack
                                spacing="16px"
                                align="left"
                                width="100%"
                            >
                                <ReactMarkdown children={content}/>
                            </VStack>
                        </Card>
                        <FooterText />
                    </VStack>
                </ReadableWrap>

            </ThemeProvider>
        </>
    );
}